<template>
  <div>
    <p>Друзья!</p>
    <p>
      Магазин подарков работает с 15 по 30 декабря. Хоть это и не отображено на
      фотографиях, у всех подарков будет фирменное брендирование. Поэтому, если
      вы давно мечтали о кружке, рюкзаке, термосе с логотипом ОТР — это ваш шанс
      😊
    </p>
    <p>
      У каждого предмета в магазине есть своя стоимость в баллах. Для
      приобретения сувениров вам необходимо набрать нужное количество баллов,
      выполняя задания адвента.
    </p>
    <p>
      Вы можете изменять содержимое своей виртуальной корзины до 12:00 30
      декабря. После этого времени мы приостановим работу магазина и зафиксируем
      сделанный вами выбор.
    </p>
    <p>Подарки можно будет получить одним из двух способов:</p>
    <ol class="mb-4">
      <li>
        Забрать самостоятельно или через коллегу в офисе ОТР (Москва и филиалы).
      </li>
      <li>
        Запросить доставку по домашнему адресу в случае проживания в городе, где
        нет нашего офиса.
      </li>
    </ol>
    <p>
      Заполните форму по этой ссылке (<a
        href="https://forms.gle/R2TKUhzVmnMHvGETA"
        target="_blank"
        >https://forms.gle/R2TKUhzVmnMHvGETA</a
      >), чтобы сообщить нам о способе получения подарка.
    </p>
    <p>
      ВАЖНО!!! Покупая подарки в магазине вы не изменяете свои показатели по
      баллам в турнирной таблице. Это лишь сумма вашей виртуальной валюты. Если
      у вас 100 баллов, а вы купили товаров на 40, то:
    </p>
    <ul class="mb-4">
      <li>в турнирной таблице у вас остались ваши 100 баллов</li>
      <li>на покупки осталось лишь 60 баллов</li>
    </ul>
    <p>
      <strong
        >Баллы за текущий день вносятся на баланс утром следующего дня.</strong
      >
    </p>
    <p><strong>⚠ Ограничения: ⚠</strong></p>
    <ol class="mb-4">
      <li>
        За обычную валюту подарки приобрести нельзя, только за игровые баллы.
      </li>
      <li>
        Передавать баллы между пользователями нельзя — «скинуться» для получения
        более ценного подарка не получится.
      </li>
      <li>Вы можете приобрести не более пяти одинаковых предметов.</li>
      <li>
        Получить подарки можно будет уже после новогодних каникул. Если вы
        выбрали получение подарка в офисе, о готовности вашего подарка к выдаче
        вам сообщат дополнительно. Если заказали доставку на домашний адрес,
        наберитесь терпения и ждите уведомления от почтовой службы.
      </li>
    </ol>

    <p><strong>📦 Доставка подарков: 📦</strong></p>
    <ul class="mb-4">
      <li>
        Сотрудники из Москвы смогут получить свои подарки в офисах на
        Дмитровском шоссе (3-й этаж, 323 кабинет, Наталия Быстрицкая) или на
        ул.Народного ополчения (офис-менеджер Мария Калягина).
      </li>
      <li>
        Для сотрудников, проживающих в городе, где имеется филиал ОТР, будет
        организована доставка на адрес офиса.
      </li>
      <li>
        Для сотрудников, работающих удаленно из других городов, будет
        организована почтовая доставка.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'OTRShopRules',
};
</script>
