<template>
  <div>
    <app-event event-id="glowbyte-2023" auth="login-only" :mapping="mapping">
      <template v-slot:logo>
        <div
          style="
            padding: 1px 4px 4px 0px;
            border-radius: 8px;
            background-color: #355d59;
            margin: 16px;
          "
        >
          <v-img :src="require('./assets/logo.png')" class="ma-4" />
        </div>
      </template>
    </app-event>
    <v-dialog :value="user && !userName" :max-width="400" persistent>
      <v-card :loading="updating">
        <v-card-title>Давайте знакомиться 🙂</v-card-title>
        <v-card-text>
          <div>
            Чтобы страна знала своих героев - укажите, пожалуйста, свои имя и
            фамилию
          </div>
        </v-card-text>
        <v-card-text>
          <v-text-field label="Как вас зовут? :)" v-model.trim="name" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="!name || name.length < 3"
            :loading="updating"
            @click="onSaveUserName"
            text
            >Сохранить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import Quest from "./Quest";
import Shop from "../otr-2022/Shop/Index.vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "AppGlowbyte2022",
  components: {
    AppEvent,
  },
  data() {
    return {
      name: "",
      updating: false,
      mapping: {
        quest: Quest,
        shop: Shop,
      },
    };
  },
  created() {
    this.name = this.userName;
  },
  watch: {
    userName() {
      this.name = this.userName;
    },
  },
  computed: {
    ...mapGetters({
      user: "event/user",
    }),
    profile() {
      return _.get(this.user, "profile");
    },
    userName() {
      return _.get(this.profile, "name");
    },
  },
  methods: {
    ...mapActions({
      updateMyProfile: "event/updateMyProfile",
    }),
    async onSaveUserName() {
      const update = { name: this.name };
      this.updating = true;
      await this.updateMyProfile(update);
    },
  },
};
</script>
